(function() {


  //主函数入口。当得到控制权时，一切已经初始化完毕。传入的参数已经可以使用。
  function custom_init($) {}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //下方代码自动生成，如不清晰，请勿修改
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  requirejs.config(
    /*INSPOINTBEGIN:REQUIREJS_CFG*/
    {
      "baseUrl": "/",
      "paths": {
        "jquery": "libs/jquery/dist/jquery.min",
        "css": 'libs/require-css/css.min',
        "image": 'libs/requirejs-plugins/src/image',
        "spin": "libs/spin.js/spin.min",
        "blockui": "libs/blockui/jquery.blockUI.min",
        "bootstrap": "libs/bootstrap/dist/js/bootstrap.min",
        "notifyjs": "libs/notifyjs/dist/notify-combined.min"
      },
      "shim": {
        "underscore": {
          "exports": "_"
        },
        "bootstrap": {
          "deps": ["jquery"]
        },
        "notifyjs": {
          "deps": ["jquery"]
        }
      }
    }
    /*INSPOINTEND:REQUIREJS_CFG*/
  );

  require(['jquery', "bootstrap", "libs/wware/wwclass.min"],
    function($, bootstrap, wwclass) {

      window.jQuery = window.$ = $;

      $.wwPageconfig = /*INSPOINTEBEGIN:PAGECONFIG*/ {}
        /*INSPOINTEEND:PAGECONFIG*/
      ;

      $().ready(function() {

        if (!$.wwclass)
          wwclass.init($);
        $.wwclass.process($("body"));

        custom_init($);
      });
    });
})();
